function openAndScrollToCollapse(event, collapseId) {
    event.preventDefault();
    const collapseElement = document.getElementById(collapseId);
    const bsCollapse = new bootstrap.Collapse(collapseElement, {
        toggle: true
    });

    collapseElement.addEventListener('shown.bs.collapse', function () {
        collapseElement.scrollIntoView({ behavior: 'smooth' });
    });

    bsCollapse.show();
}

(function ($) {

    // Vertical Timeline - by CodyHouse.co
    function VerticalTimeline(element) {
        this.element = element;
        this.blocks = this.element.getElementsByClassName("cd-timeline__block");
        this.images = this.element.getElementsByClassName("cd-timeline__img");
        this.contents = this.element.getElementsByClassName("cd-timeline__content");
        this.offset = 0.8;
        this.hideBlocks();
    }

    VerticalTimeline.prototype.hideBlocks = function () {
        if (!"classList" in document.documentElement) {
            return; // no animation on older browsers
        }
        //hide timeline blocks which are outside the viewport
        var self = this;
        for (var i = 0; i < this.blocks.length; i++) {
            (function (i) {
                if (self.blocks[i].getBoundingClientRect().top > window.innerHeight * self.offset) {
                    self.images[i].classList.add("cd-timeline__img--hidden");
                    self.contents[i].classList.add("cd-timeline__content--hidden");
                }
            })(i);
        }
    }

    VerticalTimeline.prototype.showBlocks = function () {
        if (!"classList" in document.documentElement) {
            return;
        }
        var self = this;
        for (var i = 0; i < this.blocks.length; i++) {
            (function (i) {
                if (self.contents[i].classList.contains("cd-timeline__content--hidden") && self.blocks[i].getBoundingClientRect().top <= window.innerHeight * self.offset) {
                    // add bounce-in animation
                    self.images[i].classList.add("cd-timeline__img--bounce-in", "active-content");
                    self.contents[i].classList.add("cd-timeline__content--bounce-in");
                    self.contents[i].classList.add("active-content");
                    self.images[i].classList.remove("cd-timeline__img--hidden");
                    self.contents[i].classList.remove("cd-timeline__content--hidden");
                }
            })(i);
        }
    }

    var verticalTimelines = document.getElementsByClassName("js-cd-timeline"),
        verticalTimelinesArray = [],
        scrolling = false;
    if (verticalTimelines.length > 0) {
        for (var i = 0; i < verticalTimelines.length; i++) {
            (function (i) {
                verticalTimelinesArray.push(new VerticalTimeline(verticalTimelines[i]));
            })(i);
        }

        //show timeline blocks on scrolling
        window.addEventListener("scroll", function (event) {
            if (!scrolling) {
                scrolling = true;
                (!window.requestAnimationFrame) ? setTimeout(checkTimelineScroll, 500) : window.requestAnimationFrame(checkTimelineScroll);
            }
        });
    }

    $(window).scroll(function () {

        var windowSize = $(window).height();
        var windowPosition = $(window).scrollTop();
        var timeline = $(".timeline-line").find('.line');
        var timelineHeight = timeline.innerHeight()
        var progressOffset = 300;

        timeline.each(function () {
            var elementPosition = $(this).position().top;
            var elementHeight = $(this).height();
            var elementInWindow = (windowSize + windowPosition > elementPosition) - (windowPosition - elementHeight < elementPosition);
            var timelineStart = $(this).offset().top - windowSize;
            var timelineEnd = $(this).innerHeight() + $(this).offset().top - windowSize;
            if (windowPosition + progressOffset >= timelineStart && windowPosition <= timelineEnd) {
                var onePercent = timelineHeight / 100;
                var percentageProgess = (($(window).scrollTop() - $(this).offset().top) + windowSize) / onePercent
                $(this).parent().find('.line-active').css("height", percentageProgess + '%');

                if (percentageProgess > 0) {
                    $(".timeline-line").find('.line-start-point').addClass('active');
                } else {
                    $(".timeline-line").find('.line-start-point').removeClass('active');
                }
                if (percentageProgess > 90) {
                    $(".timeline-line").find('.line-end-point').addClass('active');
                } else {
                    $(".timeline-line").find('.line-end-point').removeClass('active');
                }
            } else {
                $(this).parent().find('.line-active').removeClass("active");

            }
        });
    });

    function checkTimelineScroll() {
        verticalTimelinesArray.forEach(function (timeline) {
            timeline.showBlocks();
        });
        scrolling = false;
    }

    /** AOS animation */
    AOS.init({once: true});

    function isOnScreen(elem) {
        // if the element doesn't exist, abort
        if (elem.length == 0) {
            return;
        }
        var $window = $(window)
        var viewport_top = $window.scrollTop()
        var viewport_height = $window.height()
        var viewport_bottom = viewport_top + viewport_height
        var $elem = $(elem)
        var top = $elem.offset().top
        var height = $elem.height()
        var bottom = top + height

        return (top >= viewport_top && top < viewport_bottom) ||
            (bottom > viewport_top && bottom <= viewport_bottom) ||
            (height > viewport_height && top <= viewport_top && bottom >= viewport_bottom)
    }

    $(document).ready(function () {
        window.addEventListener('scroll', function (e) {
            if (!isOnScreen($('#ms-symbol-section'))) { /* Pass element id/class you want to check */
                $('#ms-symbol-section svg path').each(function () {
                    $(this).removeClass('aos-animate')
                });
            }
        });
    });

    /** Timeline 1 */
    $(document).ready(function () {

        let currentItemWidth = $('.timeline .timeline-item').innerWidth();
        $(".timeline .timeline-filler").css({"left": (currentItemWidth / 2)});

        $(window).on('resize', function () {
            currentItemWidth = $('.timeline .timeline-item').innerWidth();
            $(".timeline .timeline-filler").css({"left": (currentItemWidth / 2)});
        });

        $(".timeline .timeline-item").on("mouseover", function () {
            let currentWidth = 0;
            $(this).addClass('active').prevAll().addClass('active');
            $('.timeline .timeline-item.active').each(function () {
                currentWidth += $(this).innerWidth()
            })
            $(".timeline .timeline-filler").css({"max-width": (currentWidth - currentItemWidth)});
            $(".timeline .timeline-filler").width(currentWidth);
        }).on("mouseout", function () {
            $(this).removeClass('active').prevAll().removeClass('active');
            $(".timeline .timeline-filler").width(0);
        });
    });

    /** Timeline 2 */
    $(document).ready(function () {

        if ($(window).width() >= 992) {
            let currentItemWidth = $('.timeline-2 .timeline-item-col').innerWidth();

            $(window).on('resize', function () {
                currentItemWidth = $('.timeline-2 .timeline-item-col').innerWidth();
            });

            $(".timeline-2 .timeline-item-col").on("mouseover", function () {
                let currentWidth = 0;
                $(this).addClass('active active-current').prevAll().addClass('active');
                $('.timeline-2 .timeline-item-col.active').each(function () {
                    currentWidth += $(this).innerWidth()
                })
                $(".timeline-2 .timeline-filler").css({"max-width": (currentWidth - currentItemWidth)});
                $(".timeline-2 .timeline-filler").width(currentWidth);
            }).on("mouseout", function () {
                $(this).removeClass('active active-current').prevAll().removeClass('active');
                $(".timeline-2 .timeline-filler").width(0);
            });
        }
    });

    /** Project scroll */
    $(".project-card-container").on("mouseover", function () {
        let h0 = $(this).find('.project-card').innerHeight();
        let h1 = $(this).find('.project-card-img').innerHeight();

        $(this).css('--t', (h0 - h1) + 'px');
    });

    /** Gallery slider */
    $('.gallery-slider').slick({
        arrows: true,
        dots: false,
        swipeToSlide: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: $('.btn-gallery-slider-arrow-prev'),
        nextArrow: $('.btn-gallery-slider-arrow-next'),
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });

    /** Project slider */
    $('.project-slider').slick({
        arrows: true,
        dots: false,
        swipeToSlide: true,
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 1,
        prevArrow: $('.btn-project-slider-arrow-prev'),
        nextArrow: $('.btn-project-slider-arrow-next'),
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });

    /** Reference slider */
    const referenceSlider = $('.reference-slider');

    referenceSlider.slick({
        fade: true,
        arrows: true,
        dots: false,
        swipeToSlide: true,
        infinite: true,
        speed: 300,
        // autoplay: true,
        // autoplaySpeed: 6000,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: $('.reference-slider-arrow-prev'),
        nextArrow: $('.reference-slider-arrow-next'),
        // appendArrows: $('.reference-arrows')
    });

    /** Team slider */
    $('.team-slider').slick({
        arrows: true,
        dots: false,
        swipeToSlide: true,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: $('.team-slider-arrow-prev'),
        nextArrow: $('.team-slider-arrow-next'),
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });

    $(document).ready(function () {
        $('.wpcf7-form-control').on('keyup change input', function () {
            $(this).each(function () {
                if ($(this).val()) {
                    console.log($(this).parent());
                    $(this).parent().next('label').hide();
                } else {
                    $(this).parent().next('label').show();
                }
            });
        });
    });

    /** Cursor */
    $(document).ready(function () {
        if ($(window).width() > 992) {
            $(".cursor-container").each(function () {
                $(this).mouseenter(function () {
                    $('html').addClass('cursor-active');
                }).mouseleave(function () {
                    $('html').removeClass('cursor-active');
                });
            });

            var mouseX = 0,
                mouseY = 0;

            $(document).mousemove(function (e) {
                mouseX = e.pageX;
                mouseY = e.pageY;
            });

            var follower = $("#custom-cursor");
            var xp = 0, yp = 0;
            var loop = setInterval(function () {
                // change 12 to alter damping higher is slower
                xp += (mouseX - xp) / 12 - 1;
                yp += (mouseY - yp) / 12 - 1;
                follower.css({left: mouseX - 10, top: mouseY - 10});

            }, 100);
        }
    });

    /** News load more */
    // let currentPage = 1;
    // $('#load-more').on('click', function () {
    //     currentPage++;
    //     $.ajax({
    //         type: 'POST',
    //         url: '/wp-admin/admin-ajax.php',
    //         dataType: 'html',
    //         data: {
    //             action: 'articles_load_more',
    //             paged: currentPage,
    //             category: $(this).data('category'),
    //         },
    //         success: function (res) {
    //             let data = JSON.parse(res);
    //             console.log(data.max);
    //             if (currentPage >= data.max) {
    //                 $('#load-more').hide();
    //             }
    //             $('.article-list').append(data.html);
    //         }
    //     });
    // });

    // $(document).ready(function () {
    //     if ($(window).width() > 992) {
    //         $(".cursor-container").mouseenter(function(){
    //             $('html').addClass('cursor-active');
    //         }).mouseleave(function(){
    //             $('html').removeClass('cursor-active');
    //         });
    //
    //         var mouseX = 0,
    //             mouseY = 0;
    //
    //         $(document).mousemove(function (e) {
    //             mouseX = e.pageX;
    //             mouseY = e.pageY;
    //         });
    //
    //         var follower = $("#custom-cursor");
    //         var xp = 0, yp = 0;
    //         var loop = setInterval(function () {
    //             // change 12 to alter damping higher is slower
    //             xp += (mouseX - xp) / 12 - 1;
    //             yp += (mouseY - yp) / 12 - 1;
    //             follower.css({left: mouseX-55, top: mouseY-55});
    //
    //         }, 30);
    //     }
    // });

    /** Header Fixed */
    $(window).scroll(function (e) {
        if ($(window).width() < 576) {
            if ($(this).scrollTop() > 0) {
                $('body').addClass('header-fixed');
            } else {
                $('body').removeClass('header-fixed');
            }
        } else {
            if ($(this).scrollTop() > 0) {
                $('body').addClass('header-fixed');
            } else {
                $('body').removeClass('header-fixed');
            }
        }
    });

    /** Enable tooltip */
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

    /** WPCF7 Submit Button */
    // $('.wpcf7-form .wpcf7-submit[type="button"]').attr("type", "button");
    // document.addEventListener('wpcf7submit', function (event) {
    //     if ('93' == event.detail.contactFormId) {
    //         let formElement = $(event.target);
    //         let formStatus = event.detail.apiResponse.status; //mail_sent or aborted
    //
    //         if (formStatus === 'mail_sent' || formStatus === 'aborted') {
    //             $.ajax({
    //                 type: 'POST',
    //                 url: '/wp-admin/admin-ajax.php',
    //                 dataType: 'json',
    //                 data: {
    //                     action: 'voice_form_response',
    //                     status: formStatus,
    //                 },
    //                 beforeSend: function () {
    //                     formElement.closest('.modal-story').find('.modal-response').html('<div class="d-flex align-items-center justify-content-center mt-4"><div class="spinner-border spinner-border-sm" role="status"></div><span class="ps-2">Zpracovávám</span></div>');
    //                 },
    //                 success: function (res) {
    //                     formElement.closest('.modal-default').hide();
    //                     formElement.closest('.modal-story').find('.modal-response').html(res);
    //                     formElement[0].reset();
    //                 }
    //             });
    //         } else {
    //             formElement.closest('.modal-story').find('.modal-response').html('');
    //         }
    //     }
    // }, false);

    /** Scroll To Top */
    $('#scroll-to-top').on('click', function () {
        $(window).scrollTop(0);
    });
    $(window).scroll(function () {
        let scroll = $(window).scrollTop();
        if (scroll >= 600) {
            $("#scroll-to-top").addClass("active");
        } else {
            $("#scroll-to-top").removeClass("active");
        }
    });

    /** Responsive menu button */
    $('.responsive__menu_button').on('click', function () {
        $('body').toggleClass('responsive__menu_active');
    });

    $(document).on('click', 'body.responsive__menu_active .responsive__menu_overlay', function () {
        $('body').removeClass('responsive__menu_active');
    });

    $(document).ready(function() {

        $('button.accordion-button[data-bs-toggle="collapse"]').each(function() {
            $(this).on('click', function() {
                var accordionBtn = $(this);
                setTimeout(function() {
                    $('html, body').animate({
                        scrollTop: accordionBtn.offset().top-100
                    }, 0);
                }, 500);
            })
        });

        $('a[data-bs-toggle="collapse"]').each(function() {
            $(this).on('click', function() {
                var accordionAnchor = $(this).data('anchor');
                setTimeout(function() {
                    $('html, body').animate({
                        scrollTop: $(accordionAnchor).offset().top-100
                    }, 0);
                }, 500);
            })
        });
    });

})
(jQuery);